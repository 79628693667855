import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page-template.js";
import { graphql } from "gatsby";
import { BaseStyles } from "theme-ui";
export const pageQuery = graphql`
  query {
    content: allMarkdownRemark(filter: {fields: {collection: {eq: "pages"}}, frontmatter: {path: {eq: "contact"}}}) {
      edges {
        node {
          html
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BaseStyles dangerouslySetInnerHTML={{
      __html: props.data.content.edges[0].node.html
    }} mdxType="BaseStyles" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      